import { ApplicationScope } from "@superblocksteam/shared";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { createIsRouteParamsHidden } from "legacy/components/propertyControls/Routing/utils";
import {
  TriggerStepType,
  ValidStepDef,
} from "legacy/constants/ActionConstants";
import {
  ALL_CHILDREN_PROP,
  ComponentToResettableProperties,
  ComponentToSettableProperties,
  ContainerWidgets,
  ResettableWidgets,
  SettableWidgets,
  WidgetPropertyToLabel,
  WidgetPropertyToLabelByType,
} from "legacy/constants/EventTriggerPropertiesConstants";
import {
  PropsPanelCategory,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { type WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import { Flag, Flags } from "store/slices/featureFlags/models/Flags";
import { getParentPath } from "utils/dottedPaths";
import { NotificationPosition } from "../../utils/notification";
import { WidgetProps } from "./BaseWidget";
import {
  appProperty,
  keepExistingQueryParamsProperty,
  openInNewTabProperty,
  queryParamsProperty,
} from "./navigationProperties";
import type { SelectedWidgetProps } from "legacy/components/propertyControls/SelectWidgetControl";

export const getPropertyLabel = (
  propertyName: string,
  widgetType?: WidgetType,
): string | undefined => {
  if (widgetType) {
    return (
      WidgetPropertyToLabelByType[widgetType]?.[propertyName] ??
      WidgetPropertyToLabel[propertyName]
    );
  }
  return WidgetPropertyToLabel[propertyName];
};

const getDefaultPropertyToReset = (
  widget: SelectedWidgetProps,
): string | undefined => {
  return ComponentToResettableProperties[
    widget?.type as (typeof ResettableWidgets)[number]
  ]?.[0]?.value;
};

export const getResettableProperties = (
  widgetType: string,
):
  | Array<{ label: string; value: string; propertiesToReset?: string[] }>
  | undefined => {
  return ComponentToResettableProperties[
    widgetType as (typeof ResettableWidgets)[number]
  ];
};

export const getSettableProperties = (
  widgetType: string,
):
  | Array<{
      label: string;
      value: string;
      validationType?: keyof typeof VALIDATION_TYPES;
    }>
  | undefined => {
  return ComponentToSettableProperties[
    widgetType as (typeof SettableWidgets)[number]
  ]?.map(({ label, value, propertiesToSet, validationType }) => ({
    label,
    // propertiesToSet is almost always the same as value, but in some cases (i.e. codeEditor)
    // we use a more user-friendly key like "value" for value, and the actual property being set it "stringValue"
    value: (propertiesToSet?.[0] ?? value) as string,
    validationType: validationType as keyof typeof VALIDATION_TYPES,
  }));
};

const getDefaultPropertyToSet = (
  widget: SelectedWidgetProps,
): string | undefined => {
  return ComponentToSettableProperties[
    widget?.type as (typeof SettableWidgets)[number]
  ]?.[0]?.value;
};

export const shouldResetChildren = (
  widget: SelectedWidgetProps,
  propertyName: string,
) => {
  //TODO: this can be improved
  return (
    (ContainerWidgets as readonly WidgetTypes[]).includes(widget.type) &&
    propertyName.startsWith(ALL_CHILDREN_PROP)
  );
};

export const getPropertiesToReset = (
  widget: SelectedWidgetProps,
  propertyName: string,
): string[] | undefined => {
  return (
    ComponentToResettableProperties[
      widget?.type as (typeof ResettableWidgets)[number]
    ] ?? []
  )
    .find((propertyObj) => propertyObj.value === propertyName)
    ?.propertiesToReset?.filter((prop) => prop !== ALL_CHILDREN_PROP);
};

export const getPropertiesToSet = (
  widgetType: string,
  propertyName: string,
) => {
  return (
    ComponentToSettableProperties[
      widgetType as (typeof SettableWidgets)[number]
    ] ?? []
  ).find((propertyObj) => propertyObj.value === propertyName)?.propertiesToSet;
};

export const getValueMapper = (widgetType: string, propertyName: string) => {
  return (
    ComponentToSettableProperties[
      widgetType as (typeof SettableWidgets)[number]
    ] ?? []
  ).find((propertyObj) => propertyObj.value === propertyName)?.mapValue;
};

export const getValidationType = (widgetType: string, propertyName: string) => {
  const config = (
    ComponentToSettableProperties[
      widgetType as (typeof SettableWidgets)[number]
    ] ?? []
  ).find((propertyObj) => propertyObj.value === propertyName);
  if (config) {
    return {
      validationType: config.validationType,
      expectedType: config.dynamicProperties?.expected,
    };
  }
  return {};
};

const showForType = (
  type: ValidStepDef["type"],
  props: any,
  propertyPath: string,
) => {
  const baseProperty = getParentPath(propertyPath);
  const actionType = get(
    props,
    baseProperty ? `${baseProperty}.type` : "type",
    "",
  );
  return actionType !== type;
};

const PAGE_ONLY_SCOPE_TRIGGERS = new Set([
  TriggerStepType.RESET_COMPONENT,
  TriggerStepType.SET_COMPONENT_PROPERTY,
  TriggerStepType.CONTROL_MODAL,
  TriggerStepType.CONTROL_SLIDEOUT,
  TriggerStepType.RUN_APIS,
  TriggerStepType.CANCEL_APIS,
]);
const showTriggerForScope = (
  type: TriggerStepType,
  scope: ApplicationScope,
) => {
  if (scope === ApplicationScope.APP) {
    return !PAGE_ONLY_SCOPE_TRIGGERS.has(type);
  }
  return true;
};

const showForRunApi = showForType.bind(undefined, TriggerStepType.RUN_APIS);
const showForCancelApi = showForType.bind(
  undefined,
  TriggerStepType.CANCEL_APIS,
);
const showForJS = showForType.bind(undefined, TriggerStepType.RUN_JS);
const showForNavigateTo = showForType.bind(
  undefined,
  TriggerStepType.NAVIGATE_TO,
);
const showForSlideout = showForType.bind(
  undefined,
  TriggerStepType.CONTROL_SLIDEOUT,
);
const showForNavigateToApp = showForType.bind(
  undefined,
  TriggerStepType.NAVIGATE_TO_APP,
);

const showForNavigateToRoute = showForType.bind(
  undefined,
  TriggerStepType.NAVIGATE_TO_ROUTE,
);

const showForOptimizedPageLoadApis = showForType.bind(
  undefined,
  TriggerStepType.RUN_SUPERBLOCKS_OPTIMIZED_APIS,
);
const dontShowForOptimizedPageLoadApis = (props: any, propertyPath: string) =>
  !showForOptimizedPageLoadApis(props, propertyPath);

const showForUpdateQueryParams = (
  props: any,
  propPath: string,
  flags: Partial<Flags>,
  additionalHiddenData?: Record<string, unknown>,
) => {
  const isHiddenForType = showForType(
    TriggerStepType.SET_QUERY_PARAMS,
    props,
    propPath,
  );

  return isHiddenForType || !flags[Flag.ENABLE_APP_SCOPE];
};

const isRouteParamsHidden = createIsRouteParamsHidden({
  isHiddenForType: showForNavigateToRoute,
  getPath: (path: string) => getParentPath(path) ?? "",
});

const showForModal = showForType.bind(undefined, TriggerStepType.CONTROL_MODAL);
const showForTimer = showForType.bind(undefined, TriggerStepType.CONTROL_TIMER);
const showForSetVariable = showForType.bind(
  undefined,
  TriggerStepType.SET_STATE_VAR,
);
const showForResetVariable = showForType.bind(
  undefined,
  TriggerStepType.RESET_STATE_VAR,
);
const showForResetComponent = showForType.bind(
  undefined,
  TriggerStepType.RESET_COMPONENT,
);
const showForSetComponentProperty = showForType.bind(
  undefined,
  TriggerStepType.SET_COMPONENT_PROPERTY,
);
const showForAlert = showForType.bind(undefined, TriggerStepType.SHOW_ALERT);
const showForTriggerEvent = showForType.bind(
  undefined,
  TriggerStepType.TRIGGER_EVENT,
);

const showForProfile = showForType.bind(undefined, TriggerStepType.SET_PROFILE);

const isProfileHidden = (option: unknown, flags: Flags) => {
  return !flags[Flag.ENABLE_PROFILES];
};

const isEventsHidden = (option: unknown, flags: Flags) => {
  return !flags[Flag.ENABLE_CUSTOM_EVENTS];
};

const isSetQueryParamsHidden = (option: unknown, flags: Flags) => {
  return !flags[Flag.ENABLE_APP_SCOPE];
};

const MAX_PANEL_DEPTH = 2;

const optionsConfigs: Partial<PropertyPaneControlConfig> = {
  optionsFunc: (
    controlProps: any,
    featureFlags?: Partial<Flags>,
    itemScope?: ApplicationScope,
    existingOptions?: any,
  ) => {
    return (existingOptions ?? []).filter(
      ({ value }: { value: TriggerStepType }) => {
        return showTriggerForScope(value, itemScope ?? ApplicationScope.PAGE);
      },
    );
  },
  options: [
    {
      label: "Run APIs",
      value: TriggerStepType.RUN_APIS,
    },
    {
      label: "Navigate to page",
      value: TriggerStepType.NAVIGATE_TO_ROUTE,
    },
    {
      label: "Run JS (in browser)",
      value: TriggerStepType.RUN_JS,
      hasDivider: true, //*********************************************************** */
    },
    {
      label: "Open/close modal",
      value: TriggerStepType.CONTROL_MODAL,
    },
    {
      label: "Open/close slideout",
      value: TriggerStepType.CONTROL_SLIDEOUT,
    },
    {
      label: "Show alert",
      value: TriggerStepType.SHOW_ALERT,
      hasDivider: true, //*********************************************************** */
    },
    {
      label: "Set frontend variable",
      value: TriggerStepType.SET_STATE_VAR,
    },
    {
      label: "Reset frontend variable to default",
      value: TriggerStepType.RESET_STATE_VAR,
    },
    {
      label: "Set component property",
      value: TriggerStepType.SET_COMPONENT_PROPERTY,
    },
    {
      label: "Reset component to default",
      value: TriggerStepType.RESET_COMPONENT,
      hasDivider: true, //*********************************************************** */
    },
    {
      label: "Navigate to URL",
      value: TriggerStepType.NAVIGATE_TO,
    },
    {
      label: "Navigate to app",
      value: TriggerStepType.NAVIGATE_TO_APP,
    },
    {
      label: "Set query parameters",
      value: TriggerStepType.SET_QUERY_PARAMS,
      hidden: isSetQueryParamsHidden,
      hasDivider: true, //*********************************************************** */
    },
    {
      label: "Trigger event",
      value: TriggerStepType.TRIGGER_EVENT,
      hidden: isEventsHidden,
    },
    {
      label: "Start/stop timer",
      value: TriggerStepType.CONTROL_TIMER,
    },
    {
      label: "Set profile",
      value: TriggerStepType.SET_PROFILE,
      hidden: isProfileHidden,
    },
    {
      label: "Cancel APIs",
      value: TriggerStepType.CANCEL_APIS,
    },
  ],
};

export function getPopoverConfig<T = any>(
  propertyName: keyof T & string,
  helpText: string | JSX.Element,
  topLevelProps?: Partial<PropertyPaneControlConfig<T>>,
  additionalProps?: Record<string, unknown>,
  generatePanelIds?: boolean,
  label?: string,
  panelDepth = 1,
): Readonly<PropertyPaneControlConfig<T>> {
  label ??= propertyName;
  const eventTriggerControlConfig: PropertyPaneControlConfig = {
    helpText,
    propertyName,
    propertyCategory:
      panelDepth === 1 ? PropsPanelCategory.EventHandlers : undefined,
    label,
    controlType: "EVENT_TRIGGER",
    isJSConvertible: false,
    isBindProperty: false,
    isTriggerProperty: true,
    ...topLevelProps,
    ...optionsConfigs,
    panelConfig: Object.freeze({
      editableTitle: false,
      title: label,
      panelIdPropertyName: "id",
      isDeletable: (props: any) =>
        props.type !== TriggerStepType.RUN_SUPERBLOCKS_OPTIMIZED_APIS,
      adaptiveHeight: {
        maxHeight: 600,
      },
      children: [
        {
          id: generatePanelIds ? uuidv4() : undefined,
          categoryName: "Action Type",
          children: [
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "type",
              label: "Action type",
              controlType: "DROP_DOWN",
              ...optionsConfigs,
              isBindProperty: false,
              isTriggerProperty: false,
              hidden: dontShowForOptimizedPageLoadApis,
              updateHook: ({ propertyPath }: { propertyPath: string }) => {
                const parentPath = getParentPath(propertyPath) || "";
                return [
                  {
                    propertyPath: `${parentPath}.propertyName`,
                    propertyValue: undefined,
                  },
                ];
              },
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "code",
              label: "Code",
              controlType: "CODE_EDITOR",
              minLines: 5,
              hidden: showForJS,
              isBindProperty: false,
              isTriggerProperty: true,
              appendedDocLink:
                "https://docs.superblocks.com/components/functions",
              appendedDocLinkText: "JS function reference",
              placeholderText: `if (API1.response) {\n  SubmitResults.run();\n}`,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "apiNames",
              label: "APIs (executed in parallel)",
              controlType: "RUN_APIS_CONTROL",
              forceVertical: true,
              hidden: showForRunApi,
              isBindProperty: false,
              isTriggerProperty: false,
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "apiNames",
              label: "APIs",
              controlType: "RUN_APIS_CONTROL",
              hidden: showForCancelApi,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              controlType: "STATIC_TEXT",
              propertyName: "fakePropertyOptimizedApis1", // This is a fake property to force the control to be shown
              hideLabel: true,
              label: `Sequenced by Superblocks to optimize performance and handle interdependencies.  [See more](https://docs.superblocks.com/applications/events-and-interactivity/onpageload)`,
              isBindProperty: false,
              isTriggerProperty: false,
              hidden: showForOptimizedPageLoadApis,
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "fakePropertyOptimizedApis2", // This is a fake property to force the control to be shown
              label: "APIs",
              controlType: "PAGE_LOAD_APIS_CONTROL",
              headerControlType: "ADD_API",
              forceVertical: true,
              isBindProperty: false,
              isTriggerProperty: false,
              hidden: showForOptimizedPageLoadApis,
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "url",
              label: "URL",
              controlType: "INPUT_TEXT",
              hidden: showForNavigateTo,
              isBindProperty: false,
              isTriggerProperty: false,
              canExpandEditor: true,
              defaultValue: "",
              ...(additionalProps ?? {}),
            },
            openInNewTabProperty({
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "newWindow",
              hidden: showForNavigateTo,
              isBindProperty: false,
              isTriggerProperty: false,
              defaultValue: true,
              ...(additionalProps ?? {}),
            }),
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "replaceHistory",
              label: "Replace history state",
              controlType: "SWITCH",
              hidden: (props: any, propPath: string, flags: Flags) => {
                return (
                  showForNavigateTo(props, propPath) ||
                  // TODO(@omar): remove when we ship multipage
                  !flags[Flag.ENABLE_MULTIPAGE]
                );
              },
              isBindProperty: false,
              isTriggerProperty: false,
              // TODO(@omar): should probably get final copy from design/product
              helpText:
                "Replaces the current URL in the browser history using replaceState(), rather than pushing a new history entry",
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "name",
              label: "Slideout",
              controlType: "SELECT_AND_CREATE_WIDGET_CONTROL",
              widgetType: WidgetTypes.SLIDEOUT_WIDGET,
              hidden: showForSlideout,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "name",
              label: "Modal",
              controlType: "SELECT_AND_CREATE_WIDGET_CONTROL",
              widgetType: WidgetTypes.MODAL_WIDGET,
              hidden: showForModal,
              isBindProperty: true,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "direction",
              label: "Direction",
              controlType: "RADIO_BUTTON",
              options: [
                { name: "Open", value: "open" },
                { name: "Close", value: "close" },
              ],
              defaultValue: "open",
              hidden: showForSlideout,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            appProperty({
              id: generatePanelIds ? uuidv4() : undefined,
              hidden: showForNavigateToApp,
              ...(additionalProps ?? {}),
            }),
            queryParamsProperty({
              hidden: showForNavigateToApp,
              id: generatePanelIds ? uuidv4() : undefined,
              stringifyValue: true, // Note: any new props should not set this
              isJSConvertible: true,
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            }),
            openInNewTabProperty({
              hidden: showForNavigateToApp,
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "newWindowApp",
              isBindProperty: false,
              isJSConvertible: false,
              ...(additionalProps ?? {}),
            }),
            {
              propertyName: "direction",
              label: "Direction",
              controlType: "RADIO_BUTTON",
              options: [
                { name: "Open", value: "open" },
                { name: "Close", value: "close" },
              ],
              defaultValue: "open",
              hidden: showForModal,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "name",
              label: "Timer",
              controlType: "SELECT_TIMER_CONTROL",
              hidden: showForTimer,
              isBindProperty: true,
              isTriggerProperty: false,
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "command",
              label: "Command",
              controlType: "RADIO_BUTTON",
              options: [
                { name: "Start", value: "start" },
                { name: "Stop", value: "stop" },
                { name: "Toggle", value: "toggle" },
              ],
              defaultValue: "start",
              hidden: showForTimer,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "state",
              label: "Variable",
              controlType: "SELECT_STATE_VAR_CONTROL",
              hidden: showForSetVariable,
              isBindProperty: true,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "value",
              label: "Value",
              controlType: "INPUT_TEXT",
              placeholderText: "{'name': 'Billie'}",
              hidden: showForSetVariable,
              isBindProperty: false,
              isTriggerProperty: false,
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "state",
              label: "Variable",
              controlType: "SELECT_STATE_VAR_CONTROL",
              hidden: showForResetVariable,
              isBindProperty: true,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "widget",
              label: "Component",
              controlType: "SELECT_WIDGET_CONTROL",
              getWidgetTypes: () => ResettableWidgets,
              hidden: showForResetComponent,
              isBindProperty: true,
              isTriggerProperty: false,
              updateHook: ({
                propertyPath,
                propertyValue,
              }: {
                propertyPath: string;
                propertyValue: any;
              }) => {
                const parentPath = getParentPath(propertyPath) || "";
                if (!propertyValue) {
                  return [
                    {
                      propertyPath: `${parentPath}.propertyName`,
                      propertyValue: undefined,
                    },
                  ];
                }
                return [
                  {
                    propertyPath: `${parentPath}.propertyName`,
                    propertyValue: getDefaultPropertyToReset(propertyValue),
                  },
                ];
              },
              noResultsText: "No resettable components found",
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "propertyName",
              label: "Property",
              controlType: "DROP_DOWN",
              hidden: showForResetComponent,
              optionsFunc: (props?: { widget?: WidgetProps }) => {
                return (
                  ComponentToResettableProperties[
                    props?.widget?.type as (typeof ResettableWidgets)[number]
                  ] ?? []
                );
              },
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },

            {
              propertyName: "widget",
              label: "Component",
              controlType: "SELECT_WIDGET_CONTROL",
              getWidgetTypes: () => SettableWidgets,
              hidden: showForSetComponentProperty,
              isBindProperty: true,
              isTriggerProperty: false,
              updateHook: ({
                propertyPath,
                propertyValue,
              }: {
                propertyPath: string;
                propertyValue: any;
              }) => {
                const parentPath = getParentPath(propertyPath) || "";
                return [
                  {
                    propertyPath: `${parentPath}.propertyName`,
                    propertyValue: getDefaultPropertyToSet(propertyValue),
                  },
                ];
              },
              noResultsText: "No components found",
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "propertyName",
              label: "Property",
              controlType: "DROP_DOWN",
              hidden: showForSetComponentProperty,
              optionsFunc: (props?: { widget?: WidgetProps }) => {
                return (
                  ComponentToSettableProperties[
                    props?.widget?.type as (typeof SettableWidgets)[number]
                  ] ?? []
                );
              },
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "propertyValue",
              label: "Value",
              controlType: "INPUT_TEXT",
              hidden: showForSetComponentProperty,
              isBindProperty: false,
              isTriggerProperty: false,
              getDynamicProperties: (props: {
                widget: any;
                propertyName: string;
              }) => {
                return (
                  ComponentToSettableProperties[
                    props.widget?.type as (typeof SettableWidgets)[number]
                  ] ?? []
                ).find((property) => property.value === props.propertyName)
                  ?.dynamicProperties;
              },
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "message",
              label: "Message",
              controlType: "INPUT_TEXT",
              hidden: showForAlert,
              isBindProperty: false,
              isTriggerProperty: false,
              placeholderText: "Enter a message",
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "style",
              label: "Alert type",
              controlType: "DROP_DOWN",
              hidden: showForAlert,
              options: [
                { label: "Info", value: "info" },
                { label: "Success", value: "success" },
                { label: "Warn", value: "warning" },
                { label: "Error", value: "error" },
              ],
              defaultValue: "success",
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "alertDuration",
              label: "Alert Duration (seconds)",
              controlType: "INPUT_TEXT",
              hidden: showForAlert,
              isBindProperty: false,
              isTriggerProperty: false,
              defaultValue: 4,
              placeholderText: "Enter duration in seconds",
              helpText:
                "The duration before the alert is automatically dismissed. Set to 0 to require explicit dismissal",
              validation: VALIDATION_TYPES.NUMBER,
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "alertPosition",
              label: "Alert position",
              controlType: "DROP_DOWN",
              hidden: showForAlert,
              options: [
                {
                  label: "Bottom right",
                  value: NotificationPosition.bottomRight,
                },
                {
                  label: "Bottom left",
                  value: NotificationPosition.bottomLeft,
                },
                {
                  label: "Bottom center",
                  value: NotificationPosition.bottom,
                },
                { label: "Top right", value: NotificationPosition.topRight },
                { label: "Top left", value: NotificationPosition.topLeft },
                { label: "Top center", value: NotificationPosition.top },
              ],
              defaultValue: "bottomRight",
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "profileAction",
              label: "Action",
              controlType: "RADIO_BUTTON_GROUP",
              options: [
                { label: "Set", value: "set" },
                { label: "Set to default", value: "unset" },
              ],
              defaultValue: "set",
              hidden: showForProfile,
              isBindProperty: false,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "profileId",
              label: "Profile ID",
              controlType: "INPUT_TEXT",
              forceVertical: true,
              hidden: (props: any, propertyPath: string) => {
                const baseProperty = getParentPath(propertyPath);
                const actionType = get(
                  props,
                  baseProperty ? `${baseProperty}.type` : "type",
                  "",
                );
                const profileAction = get(
                  props,
                  baseProperty
                    ? `${baseProperty}.profileAction`
                    : "profileAction",
                );
                return (
                  actionType !== TriggerStepType.SET_PROFILE ||
                  (actionType === TriggerStepType.SET_PROFILE &&
                    profileAction !== "set" &&
                    profileAction !== undefined)
                );
              },
              isBindProperty: false,
              isTriggerProperty: false,
              placeholderText: "{{Global.profiles.default.id}}",
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "event",
              label: "Event",
              controlType: "SELECT_EVENT_CONTROL",
              hidden: showForTriggerEvent,
              isBindProperty: true,
              isTriggerProperty: false,
              ...(additionalProps ?? {}),
            },
            {
              propertyName: "eventPayload",
              label: "Arguments",
              controlType: "EVENT_ARGUMENTS_CONTROL",
              hidden: showForTriggerEvent,
              hideLabel: true,
              isBindProperty: true,
              isTriggerProperty: false,
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "routeId",
              label: "Page",
              controlType: "SELECT_ROUTE_DROP_DOWN",
              hidden: showForNavigateToRoute,
              isBindProperty: false,
              isTriggerProperty: false,
              placeholderText: "Select a page",
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "routeParams",
              label: "Route parameters",
              helpText:
                "Values for the route parameters required by this route",
              controlType: "SELECT_ROUTE_PARAMS",
              hidden: isRouteParamsHidden,
              getAdditionalHiddenData: {
                routes: getRoutesList,
              },
              isBindProperty: false,
              isTriggerProperty: false,
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            {
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "queryParams",
              label: "Query parameters",
              controlType: "KEY_VALUE_INPUT",
              stringifyValue: true, // Note: any new props should not set this
              headerControlType: "ADD_KEY_VALUE",
              hidden: showForNavigateToRoute,
              isBindProperty: false,
              isTriggerProperty: false,
              isJSConvertible: true,
              // when its a dynamic property
              canExpandEditor: true,
              ...(additionalProps ?? {}),
            },
            keepExistingQueryParamsProperty({
              hidden: showForNavigateToRoute,
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "keepQueryParams",
              isBindProperty: false,
              canExpandEditor: true,
              defaultValue: false,
              ...(additionalProps ?? {}),
            }),
            openInNewTabProperty({
              hidden: showForNavigateToRoute,
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "newWindow",
              isBindProperty: false,
              canExpandEditor: true,
              defaultValue: false,
              ...(additionalProps ?? {}),
            }),
            queryParamsProperty({
              id: generatePanelIds ? uuidv4() : undefined,
              stringifyValue: true, // Note: any new props should not set this
              defaultValue: JSON.stringify({ param1: "" }),
              isBindProperty: false,
              canExpandEditor: true,
              hidden: showForUpdateQueryParams,
              ...(additionalProps ?? {}),
            }),
            keepExistingQueryParamsProperty({
              hidden: showForUpdateQueryParams,
              id: generatePanelIds ? uuidv4() : undefined,
              propertyName: "keepQueryParams",
              isBindProperty: false,
              canExpandEditor: true,
              defaultValue: true,
              ...(additionalProps ?? {}),
            }),
          ],
        },
      ],
    }),
  };

  if (panelDepth < MAX_PANEL_DEPTH) {
    const panelConfig = eventTriggerControlConfig.panelConfig?.children;
    panelConfig?.[0].children?.push({
      ...getPopoverConfig<any>(
        "onSuccess",
        "Triggers once all APIs finish executing successfully",
        // no need to show duplicate subheader api onSuccess
        {
          ...topLevelProps,
          subheader: undefined,
          label: "onSuccess",
          headerControlType: undefined,
        },
        additionalProps,
        generatePanelIds,
        "onSuccess",
        panelDepth + 1,
      ),
      hidden: showForRunApi,
      showAddButton: true,
    });

    panelConfig?.[0].children?.push({
      ...getPopoverConfig<any>(
        "onError",
        "Triggers if there are any errors when running the APIs",
        {
          ...topLevelProps,
          subheader: undefined,
          label: "onError",
          headerControlType: undefined,
        },
        additionalProps,
        generatePanelIds,
        "onError",
        panelDepth + 1,
      ),
      hidden: showForRunApi,
      showAddButton: true,
    });
  }

  return eventTriggerControlConfig as Readonly<PropertyPaneControlConfig>;
}
